import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserAlt, faCogs, faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { HOME, ABOUT, PROJECTS, CONTACT } from '../globals'
import './navbar.css';

class NavBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            toggleNavMenuBtn: true,
            showNavBar: false,
            logoActive: false
        }
        this.navRef = React.createRef(null)
    }

    toggleNavBar = () => {
        if (!this.state.showNavBar) {
            this.toggleNavMenuBtn()
            this.showNavBar()
            this.setLogoActive()
        } else {
            this.toggleNavMenuBtn()
            this.showNavBar()
            setTimeout(() => {
                this.setLogoActive()
            },
                1000
            );
        }
    }

    componentDidMount() {
        document.addEventListener('touchmove', this.handleTouch);
        document.addEventListener('mousemove', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('touchmove', this.handleTouch);
        document.removeEventListener('mousemove', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
        let navBtnInput = document.getElementById("toggle-input-nav-btn")

        //Check if navbar is openend, check if cursus is out of the navbar and check 
        //if the mouse cursor is further than the width of the navbar + screen width divide by 15.
        if(this.state.showNavBar && !this.navRef.current.contains(event.target) &&
         event.clientX > (this.navRef.current.offsetWidth + (window.innerWidth / 15))){
            navBtnInput.checked = false;
            this.toggleNavBar()
        }

    }

    handleTouch = (event) => {
        let navBtnInput = document.getElementById("toggle-input-nav-btn")

        if (this.state.showNavBar && !this.navRef.current.contains(event.target)
        ) {
            navBtnInput.checked = false;
            this.toggleNavBar()
        }
    }


    toggleNavMenuBtn = () => {
        this.setState(prevState => ({
            toggleNavMenuBtn: !prevState.toggleNavMenuBtn
        }))
    }

    showNavBar = () => {
        this.setState(prevState => ({
            showNavBar: !prevState.showNavBar
        }))
    }

    setLogoActive = () => {
        this.setState(prevState => ({
            logoActive: !prevState.logoActive
        }))
    }

    render() {
        return (

            <nav className="navbar">
                <div className="nav-menu-btn-container">
                    <div className="nav-menu-btn">
                        <input id="toggle-input-nav-btn" type="checkbox" onClick={this.toggleNavBar} />
                        <span id="one"></span>
                        <span id="two"></span>
                        <span id="three"></span>
                    </div>
                </div>
                <div ref={this.navRef} className={this.state.showNavBar ? "navigation-bar nav-in" : "navigation-bar nav-out"}>
                    <div className="nav-logo-container">
                        <svg viewBox="0 0 202.49197 127.64097" className={this.state.logoActive ? "logo-container show" : "logo-container"}>
                            <g transform="translate(-3.4418002,-35.475066)" id="layer1">
                                <path className="logo-stroke" id="bUpper"
                                    d="m 10.690781,39.742335 41.426776,-0.267269 c 0,0 16.704347,0 23.920625,16.704344 7.216274,16.704347 -5.345393,28.86511 -5.345393,28.86511 0,0 -4.409946,7.082637 -18.976136,8.819897"
                                >
                                    <animate attributeType="XML" attributeName="stroke-dashoffset" from="200" to="0" dur="1s" id="bUpper-anim" repeatCount="indefinite" begin="indefinite" />
                                    <animate attributeType="CSS" attributeName="opacity" from="0" to="1" dur="1s" repeatCount="indefinite" begin="indefinite" />
                                </path>
                                <path className="logo-stroke" id="bLower"
                                    d="m 3.4469336,158.95652 52.0507424,0.0668 c 0,0 16.904798,-0.4009 25.791509,-15.10073 8.886707,-14.69982 4.610399,-27.99648 1.069078,-33.20824 -3.541321,-5.21175 -5.545844,-7.75081 -5.545844,-7.75081"
                                />
                                <path className="logo-stroke" id="nLeft"
                                    d="M 113.0079,163.50658 112.77157,48.664144 c 0,0 0,-6.541961 6.33342,-8.499772 6.33341,-1.957812 9.21654,5.109413 9.73645,5.586927 0.51991,0.477518 22.87592,44.934187 22.87592,44.934187"
                                />
                                <path className="logo-stroke" id="nRight"
                                    d="M 201.93378,35.060539 201.79204,150.2428 c 0,0 0.0472,7.51508 -6.09487,8.69392 -6.14211,1.17884 -9.26042,-3.24181 -10.11086,-5.64859 -0.85045,-2.4068 -24.85194,-50.34619 -24.85194,-50.34619"
                                />
                            </g>


                        </svg>
                    </div>
                    <ul className="nav-btn-container">
                        <li className="nav-item">
                            <button className={(this.props.active === HOME) ? "nav-btn nav-active" : "nav-btn"}
                                onClick={() => { this.props.setActive(HOME); this.props.scrollToPage(HOME) }}>
                                <FontAwesomeIcon className="nav-icon" icon={faHome} />
                                <p className="nav-text">{HOME}</p>
                            </button>
                        </li>
                        <li className="nav-item">
                            <button className={(this.props.active === ABOUT) ? "nav-btn nav-active" : "nav-btn"}
                                onClick={() => { this.props.setActive(ABOUT); this.props.scrollToPage(ABOUT) }}>
                                <FontAwesomeIcon className="nav-icon" icon={faUserAlt} />
                                <p className="nav-text">{ABOUT}</p>
                            </button>
                        </li>
                        <li className="nav-item">
                            <button className={(this.props.active === PROJECTS) ? "nav-btn nav-active" : "nav-btn"}
                                onClick={() => { this.props.setActive(PROJECTS); this.props.scrollToPage(PROJECTS) }}>
                                <FontAwesomeIcon className="nav-icon" icon={faCogs} />
                                <p className="nav-text">{PROJECTS}</p>
                            </button>
                        </li>
                        <li className="nav-item">
                            <button className={(this.props.active === CONTACT) ? "nav-btn nav-active" : "nav-btn"}
                                onClick={() => { this.props.setActive(CONTACT); this.props.scrollToPage(CONTACT) }}>
                                <FontAwesomeIcon className="nav-icon" icon={faAddressCard} />
                                <p className="nav-text">{CONTACT}</p>
                            </button>
                        </li>
                    </ul>
                    <div className="spacer-mobile-btn"></div>
                    <ul className="social-container">
                        <li className="nav-item">
                            <a className="nav-social-link" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/brady-neeft/">
                                <FontAwesomeIcon className="nav-social-icon" icon={faLinkedin} />
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-social-link" target="_blank" rel="noopener noreferrer" href="https://github.com/NeeftB">
                                <FontAwesomeIcon className="nav-social-icon" icon={faGithub} />
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        )
    }
}


export default NavBar;