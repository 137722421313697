export const HOME = "home";
export const ABOUT = "about me";
export const PROJECTS = "projects";
export const CONTACT = "contact";

export const pageNames = {
    HOME: "home",
    ABOUT: "about me",
    PROJECTS: "projects",
    CONTACT: "contact"
}