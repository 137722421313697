import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './contact.css'
import { CONTACT } from '../globals'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';


function Contact() {
    return (
        <div id={CONTACT} className="contact">
            <div className="category-container">
                <p className="category-title">Contact</p>
                <div id="horizontal-line"></div>

                <div className="contact-content-container">
                    <div className="contact-text-container">
                        <p className="contact-title-text">Get in touch?</p> <br />
                        <p className="contact-text">

                            Do you want to know me better, have a question regarding work
                            or just want to ask me something?
                        <br /><br />
                            Do not hesitate and reach me by my socials:
                        </p>
                        <div className="contact-info">
                            <a href="mailto:brady_neeft@hotmail.com?Subject=Let's%20get%20in%20touch!" target="_top"
                                rel="noopener noreferrer">
                                <FontAwesomeIcon className="contact-icon" icon={faEnvelope} />
                                <p className="contact-icon-text">Mail</p>
                            </a>
                            <a href="https://www.instagram.com/bradyneeft/" target="_blank"
                                rel="noopener noreferrer">
                                <FontAwesomeIcon className="contact-icon" icon={faInstagram} />
                                <p className="contact-icon-text">Instagram</p>
                            </a>
                            <a href="https://www.linkedin.com/in/brady-neeft/" target="_blank"
                                rel="noopener noreferrer">
                                <FontAwesomeIcon className="contact-icon" icon={faLinkedin} />
                                <p className="contact-icon-text">LinkedIn</p>
                            </a>
                        </div>
                    </div>

                    {/* <div className="contact-form-container">
                        <p className="title-form">Send a message:</p>
                        <form>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input type="text" className="form-control" id="name" placeholder="Please your name" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email address</label>
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Please your email" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Message/Question:</label>
                                <textarea className="form-control" id="message" rows="6" placeholder="Please your message or question" />
                            </div>
                            <button type="submit" className="form-btn">Send</button>
                        </form>
                    </div> */}

                </div>
            </div>
        </div>

    )
}


export default Contact;