import React from 'react';
import { PROJECTS } from '../globals';
import MobileSlider from './mobileslider'
import projectsInfo from '../assets/JSON/projects-info'
import './projects.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


class Projects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectsInfo: projectsInfo["Projects"],
            title: null,
            languages: null,
            info: null,
            codeLink: null,
            techniqueInfo: null,
            projectsActive: false,
            showProjects: false,
            showProjectInfo: false,
        }
        this.projectBox = React.createRef(null)
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll, { passive: true });
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    selectProject = (id) => {
        var selectedProject = this.state.projectsInfo[id]
        this.setState({
            title: selectedProject.title,
            languages: selectedProject.languages,
            info: selectedProject.info,
            techniqueInfo: selectedProject.techniqueInfo,
            codeLink: selectedProject.code
        })
    }

    scrollToProjectTop = () => {
        var projectTop = document.getElementById(PROJECTS)
        if (projectTop) {
            projectTop.scrollIntoView({
                behavior: 'smooth'
            })
        }
    }

    handleBackBtn = () => {
        this.showProjects()
        this.scrollToProjectTop();
        setTimeout(() => {
            this.showProjectInfo()
            this.activateProjectsContainer()
        },
            1000
        );
    }

    handleProjectClick = (id) => {
        this.showProjects()
        this.selectProject(id);
        this.scrollToProjectTop();
        setTimeout(() => {
            this.activateProjectsContainer()
            this.showProjectInfo()
        },
            1000
        );
    }

    showProjectInfo = () => {
        this.setState(prevState => ({
            showProjectInfo: !prevState.showProjectInfo
        }));
    }

    showProjects = () => {
        this.setState(prevState => ({
            showProjects: !prevState.showProjects
        }));
    }

    activateProjectsContainer = () => {
        this.setState(prevState => ({
            projectsActive: !prevState.projectsActive
        }));
    }

    handleScroll = () => {
        const currentScrollY = window.scrollY;

        if (currentScrollY > (this.projectBox.current.offsetTop - (this.projectBox.current.offsetHeight / 3))
            && !this.state.showProjectInfo) {
            this.setState({ projectsActive: true })
        }
        else if (currentScrollY < (this.projectBox.current.offsetTop - window.innerHeight)) {
            this.setState({ projectsActive: false, showProjectInfo: false, showProjects: false })
        }
    };

    render() {
        return (
            <div id={PROJECTS} ref={this.projectBox} className="projects">
                <div className="category-container">
                    <p className="category-title">Projects</p>
                    <div id="horizontal-line"></div>
                </div>
                {
                    this.state.projectsActive &&
                    <div className={this.state.showProjects ? "projects-content-container projects-exit" : "projects-content-container"}>
                        {this.state.projectsInfo.map((project, i) => (
                            <div key={i} className="project-container">
                                <div id={i} onClick={(e) => this.handleProjectClick(e.currentTarget.id)} className="project-sample-container">
                                    <img className="project-head-image" src={require(`../assets/images/projects/${project.title}/main.png`)} alt="project front" />
                                    <p className="project-title">{project.title}</p>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                }

                {this.state.showProjectInfo &&
                    <div className={this.state.showProjects ? "project-content-container" : "project-content-container projects-exit"}>
                        <div className="project-info-container">
                            <p className="project-info-title">{this.state.title}</p>
                            <p className="project-info-languages">
                                {this.state.languages}</p>
                            <div className="project-info-text-container">
                                <p className="project-info-text">
                                    {this.state.info}
                                </p>
                                <p className="project-technology-text">
                                    {this.state.techniqueInfo}
                                </p>
                            </div>
                            <div className="project-images-carousel-mobile">
                                <MobileSlider title={this.state.title} />
                            </div>
                            <div className="project-images-container">
                                <div className="project-image-container first-column">
                                    <img id="third-image" className="project-image" src={require(`../assets/images/projects/${this.state.title}/500x1000.png`)} alt="Third" />
                                </div>
                                <div className="project-image-container second-column">
                                    <img id="second-image" className="project-image" src={require(`../assets/images/projects/${this.state.title}/500x500_one.png`)} alt="Second" />
                                    <img id="fifth-image" className="project-image" src={require(`../assets/images/projects/${this.state.title}/500x500_two.png`)} alt="Fifth" />
                                </div>
                                <div className="project-image-container third-column">
                                    <img id="first-image" className="project-image" src={require(`../assets/images/projects/${this.state.title}/500x700.png`)} alt="First" />
                                    <img id="fourth-image" className="project-image" src={require(`../assets/images/projects/${this.state.title}/500x300.png`)} alt="Fourth" />
                                </div>
                            </div>
                            <div className="project-info-buttons">
                                <button onClick={this.handleBackBtn} className="project-btn">BACK</button>
                                {this.state.codeLink !== "" &&
                                    <a href={this.state.codeLink} target="_blank" rel="noopener noreferrer" className="project-btn">CODE</a>}
                            </div>
                        </div>
                    </div>
                }
            </div>

        )
    }
}


export default Projects;
