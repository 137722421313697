import React from 'react'
import './footer.css'

function Footer() {
    return (
        <div className="footer-container">
            <div className="footer-link-container">
                <a className="footer-link" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/brady-neeft/">
                    Linkedin
                </a>
                <a className="footer-link" target="_blank" rel="noopener noreferrer" href="https://github.com/NeeftB">
                    Github
                </a>
            </div>
            <p>&copy;2020. BNOFFICIAL</p>
        </div>
    )
}

export default Footer