import React from 'react';
import './about.css'
import ProfilePhoto from '../assets/images/about_me_photo.png';
import { ABOUT } from '../globals'

function About() {
    return (
        <div id={ABOUT} className="about">
            <div className="category-container">
                <p className="category-title">About Me</p>
                <div id="horizontal-line"></div>
            </div>
            <div className="about-content-container">
                <div className="about-photo-container">
                    <img src={ProfilePhoto} className="about-me-photo" alt="profile" />
                </div>
                <div className="about-text-container">
                    <div className="about-text">
                        <p className="personal-description-title">
                            A little about me
                            </p>
                        <p className="personal-description">
                            My name is Brady Neeft. I am an enthusiastic starting software developer. I started learning software
                            development about 3 years ago. The idea of building something from scratch gave me such an energy boost
                            that I wanted to make my work out of it.
                        <br /><br />
                            I started to follow a bachelor program which I have almost finished.
                            During the study I came into contact with frontend development and UI/UX which both aroused my interest.
                            That is why I want to focus on these two expertise areas in the coming period.
                        <br /><br />
                            However, I'm a curious person so I am always open to new challenges.
                            </p>
                    </div>
                </div>
            </div>
        </div>

    )
}


export default About;
