import React, { useState, useEffect} from 'react';
import NavBar from './components/navbar';
import MainPage from './components/mainpage';
import './App.css';
import { HOME, ABOUT, PROJECTS, CONTACT } from './globals'
import About from './components/about';
import Projects from './components/projects';
import Contact from './components/contact';
import Footer from './components/footer'

function App() {
  const [navMenuBtnActive, setNavMenuBtnActive] = useState("home")

  function scrollToPage(pageName) {
    var element = document.getElementById(pageName)
    if (element && (pageName !== CONTACT || navMenuBtnActive === PROJECTS)) {
      element.scrollIntoView({
        behavior: 'smooth'
      })
    } else if (element && pageName === CONTACT) {
      if (element.offsetTop !== (window.innerHeight - element.innerHeight)) {
        element.scrollIntoView({ behavior: 'smooth' })
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' })
        },
          600
        );
      }


    }
  }

  const ActivateBtnScroll = () => {
    const homePage = document.getElementById(HOME)
    const aboutPage = document.getElementById(ABOUT)
    const projectsPage = document.getElementById(PROJECTS)
    const contactPage = document.getElementById(CONTACT)
    const currentScrollY = window.scrollY;

    if (currentScrollY >= homePage.offsetTop &&
      currentScrollY < (homePage.offsetTop + (homePage.offsetHeight / 10))) {
      setNavMenuBtnActive(HOME)
    }
    else if
      (currentScrollY > (aboutPage.offsetTop - (aboutPage.offsetHeight / 10)) &&
      currentScrollY < (aboutPage.offsetTop + (aboutPage.offsetHeight / 10))) {
      setNavMenuBtnActive(ABOUT)
    }
    else if
      (currentScrollY > (projectsPage.offsetTop - (projectsPage.offsetHeight / 10)) &&
      currentScrollY < (projectsPage.offsetTop + (projectsPage.offsetHeight / 10))) {
      setNavMenuBtnActive(PROJECTS)
    }
    else if
      (currentScrollY > (contactPage.offsetTop - (contactPage.offsetHeight / 10)) &&
      currentScrollY < (contactPage.offsetTop + (contactPage.offsetHeight / 10))) {
      setNavMenuBtnActive(CONTACT)
    }

  };
  useEffect(() => {
    // Bind the event listener
    window.addEventListener("scroll", ActivateBtnScroll, { passive: true });
    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener("scroll", ActivateBtnScroll);
    };
  })

  return (
    <div id="app" className="App">
      <NavBar active={navMenuBtnActive} setActive={setNavMenuBtnActive} scrollToPage={scrollToPage} />
      <div className="content-container">
        <MainPage />
        <About />
        <Projects />
        <Contact />
        <Footer />
      </div>
    </div>

  );
}

export default App;
