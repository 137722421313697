import React from 'react';
import { HOME } from '../globals'
import ProfilePhoto from '../assets/images/profile_photo_dummy.png';
import './mainpage.css'

function MainPage() {
    return (
        <div id={HOME} className="mainpage">
            <div className="category-container">
                <p className="category-title">Welcome</p>
                <div id="horizontal-line"></div>
            </div>
            <div className="mainpage-content-container">
                <div className="profile-photo-container">
                    <img src={ProfilePhoto} className="profile-photo" alt="profile" />
                </div>
                <div className="intro-text-container">
                    <p className="intro-text">
                        <span className="welcome-text">Nice to meet you. </span> <br />
                        I'm Brady Neeft<br />
                        <span className="function-description">--Software Developer--</span>
                    </p>
                </div>
            </div>
        </div>

    )
}

export default MainPage;