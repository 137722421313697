import React from "react";
import Slider from 'react-slick';

import './mobileslider.css'

export default class MobileSlider extends React.Component {
    constructor(props) {
        super(props)
        this.title = props.title
    }
    render() {
        const settings = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        return (
            <div>
                <Slider {...settings}>
                    <div>
                        <img id="" className="project-image-mobile" src={require(`../assets/images/projects/${this.title}/500x1000.png`)} alt="Third" />
                    </div>
                    <div>
                        <img id="" className="project-image-mobile" src={require(`../assets/images/projects/${this.title}/500x500_one.png`)} alt="Second" />
                        <img id="" className="project-image-mobile" src={require(`../assets/images/projects/${this.title}/500x500_two.png`)} alt="Fifth" />
                    </div>
                    <div>
                        <img id="" className="project-image-mobile" src={require(`../assets/images/projects/${this.title}/500x700.png`)} alt="First" />
                        <img id="" className="project-image-mobile" src={require(`../assets/images/projects/${this.title}/500x300.png`)} alt="Fourth" />
                    </div>
                </Slider>
            </div>
        );
    }
}
