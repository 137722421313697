export default {
    'Projects': [
        {'title': 'web game',
         'languages': 'Html | Css | Js | Python | PostgreSQL | Docker', 
         'info': 'A web based game with the provisional name: Gangster of Paradise. ' +
         'Become a gangster and work your way up to become the greatest with your friends.' +
         'The game is about tactics, collabaration, gather information but most importantly fun. ',
        'techniqueInfo': 'The frontend of the project is build with React. ' +
        'The API is build with Python which communicates with a PostgreSQL database. ' + 
        'The whole project runs in a Docker environment so it is easily to deploy.', 
        'code': ''        
        },
        {'title': 'blog site',
        'languages': 'Html | Css | Js | Java | Hibernate | MySQL', 
         'info': 'A website for travellers to put their blog on.' + 
         'The idea is that people can place and read blogs and that they can contact other people for tips, ' + 
         'experiences, etc.',
         'techniqueInfo': 'The frontend is created with the Angular Framework. Html, Css, Bootstrap, ' + 
         'Typescript and Javascript is used. The backend exist of an API which is created in Java. It uses ' +
         'the Hibernate Framework to communicate with the MySQL Database.',
        'code': ''        
        },
        {'title': 'android apps',
        'languages': 'Kotlin', 
         'info': 'During my school career I have made several android apps. The apps contain the basics of Kotlin. ' + 
         'A few examples of the apps I made: Pokedex, Notepad, Game Backlog, Rock-Paper-Scissors game. ',
         'techniqueInfo': 'Some of the techniques that are used: Retrofit for API calls, Glide to load images from the web, Viewmodels with ' +
         'livedata to show content on the screen and Room for local storage.',
        'code': 'https://github.com/NeeftB?utf8=%E2%9C%93&tab=repositories&q=&type=&language=kotlin'        
        }
    ]
}